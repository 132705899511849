.ck-editor__editable {
    min-height: 200px;
}

.explore-page .ui.secondary.menu {
    width: fit-content;
    display: flex;
    flex-wrap: wrap;
}
.explore-page .ui.secondary.menu .item{
    border: 1px solid #ccc;
    margin: 8px; 
    box-sizing: border-box;
}

.explore-page .ui.secondary.menu .active.item{
    background-color: #002244;
    color: white
}

.explore-page .ui.secondary.menu .active.item:hover{
    background-color: #224466;
    color: white
}

